import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import BannerSection from '../components/BlogIndex/BannerSection';
import Filter from '../components/BlogIndex/Filter';
import Posts from '../components/BlogIndex/Posts';
import TrendingTopics from '../components/BlogIndex/TrendingTopics';

/**
* Main blog page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/

const Blog = ({ data, location, pageContext }) => {
    const wrapper = useRef(null);

    const meta = data.meta.nodes[0];
    const posts = data.allGhostPost.edges;
    const tags = data.tags.edges;
    const trendingTags = data.trendingTag.edges;
    const featuredPost = data.allFeaturedPost.edges;
    const latestPost = data.allLatestPost.edges; // latest 5 posts without featured flag turned on

    useEffect(() => {
        if (location.pathname.includes('page')) {
            let elmnt = wrapper.current;

            var headerOffset = 80;
            var elementPosition = elmnt.getBoundingClientRect().top;
            var offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }, [wrapper]);

    return (
        <>
            <MetaData
                location={location}
                data={data}
                title={meta?.meta_title}
                description={meta?.meta_description}
            />
            <Layout isHome={true}>
                <BannerSection featuredPost={
                    // we are showing the latest posts if no featured posts are marked in the section
                    featuredPost.length > 0 ?
                        featuredPost.length < 5 ?
                            featuredPost.concat(latestPost.slice(0, latestPost.length - featuredPost.length))
                            : featuredPost
                        : latestPost
                }
                />
                <div ref={wrapper} >
                    <Filter tags={tags} />
                </div>
                <Posts posts={posts} pageContext={pageContext} indexRoute='/blog' />
                <TrendingTopics trendingTags={trendingTags} />
            </Layout>
        </>
    )
}

Blog.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Blog

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        filter:{
            tags: {elemMatch: {slug: {ne: "opinion"}}}
        }
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }

    allFeaturedPost: allGhostPost(
        limit: 5,
        filter:{
            featured:{eq: true}
            tags: {elemMatch: {slug: {ne: "opinion"}}}
        }
        sort: { order: DESC, fields: [published_at] },
    ) {
        edges {
                node {
                    ...GhostPostFields
                }
            }
        }

    tags: allGhostTag(
        filter:{
             visibility:{ eq: "public" }
             slug:{ ne: "opinion" }
            }
        sort: { order: ASC, fields: name }
        ) {
            edges {
                node {
                    slug
                    name
                }
            }
        }

    allLatestPost: allGhostPost(
        limit: 5,
        filter:{
            featured:{eq: false}
            tags: {elemMatch: {slug: {ne: "opinion"}}}
        }
  	    sort: { order: DESC, fields: [published_at] }
        ){
         edges{
            node{
                ...GhostPostFields
            }
        }
    }

    trendingTag: allGhostTag(
        limit: 10,
  	    sort: { order: DESC, fields: [postCount] }
        filter:{
            visibility:{ eq: "public" }
    	    accent_color:{eq: "#FF0000"}
        }
        ){
            edges{
        node{
            name
            slug
        }
    }
  }

        meta: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: { eq: "#blog" }}}
                }
            ){
                nodes {
                    meta_title
                    meta_description
        }
    }
}
`
