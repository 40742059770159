import React from 'react'
import PropTypes from 'prop-types'
import { TrendingLinkWrapper, TrendingTopicsContainer } from './Components'
import Text from '../common/ui/Text'
import CustomLink from '../common/ui/CustomLink'
import { tagRouteBaseUrl } from '../../constants/strings'

const TrendingTopics = props => {
    return (
        <TrendingTopicsContainer color='CHQBOOK_BLUE_LIGHT'>
            <Text
                align='center'
                size='md'
                color='MEDIUM_GREY'
                padding='0 0 10px 0'
            >Trending Topics
            </Text>
            <TrendingLinkWrapper>
                {
                    props.trendingTags instanceof Array &&
                    props.trendingTags.map((item, i, arr) => {
                        return (
                            <React.Fragment key={i}>
                            <CustomLink
                                to={`${tagRouteBaseUrl}/${item.node.slug}`}
                                size='md'
                                weight='large'
                                color='CHQBOOK_BLUE_DARK'
                                margin='0 10px'
                            >
                                {item.node.name}
                            </CustomLink>
                            {i != arr.length - 1 &&
                            <div> &nbsp;
                                <Text
                                size='md'
                                weight='large'
                                color='CHQBOOK_BLUE_DARK'
                                >.</Text>&nbsp;</div>}
                            </React.Fragment>
                        );
                    })
                }
            </TrendingLinkWrapper>
        </TrendingTopicsContainer>
    )
}

TrendingTopics.propTypes = {

}

export default TrendingTopics
