import React from 'react'
import PropTypes from 'prop-types'
import { PostCard, Pagination } from '../../components/common'
import { Container, PostFeed } from './Components'

const Posts = ({ posts, pageContext, indexRoute, containerWidth, gridDesktopColumns, isOpinion, hideFooter }) => {
    return (
        <>
            <Container
                margin='65px auto'
                sMargin='38px auto'
                sWidth='90%'
                containerWidth={containerWidth}
                >
                <PostFeed gridDesktopColumns={gridDesktopColumns}>
                    {posts.map(({ node }, i) => {
                        // The tag below includes the markup for each post - components/common/PostCard.js
                        if (i > 3) {
                            return <PostCard
                                key={node.id}
                                post={node}
                                sMinContainerHeight='100%'
                                miniCard={true}
                                hideFooter={hideFooter}
                                isOpinion={isOpinion}
                            />
                        } else {
                            return <PostCard
                                key={node.id}
                                sMinContainerHeight='100%'
                                post={node}
                                hideFooter={hideFooter}
                                isOpinion={isOpinion}
                            />
                        }
                    })}
                </PostFeed>
                <Pagination
                    pageContext={pageContext}
                    indexRoute={indexRoute}
                />
            </Container>
        </>
    )
}

Posts.propTypes = {

}

export default Posts
