import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './Components'
import Text from '../../components/common/ui/Text';
import CustomLink from '../../components/common/ui/CustomLink';
import Tag from '../../components/common/ui/Tag';
import { tagRouteBaseUrl } from '../../constants/strings'

const Filter = ({ tags, slug }) => {
    return (
        <>
            <Text
                align='center'
                color='MEDIUM_GREY'
                margin='0 0 20px 0'
                sMargin='0 0 17px 0'
            >Filter by category:</Text>
            <Container display='flex' jContent='center' height='100%' flexWrap='wrap'>
                <CustomLink
                    to={`/blog`}
                    height='100%'
                    padding='0 10px'
                    margin='0 0 10px 0'
                >
                    <Tag
                        size='xs'
                        color='TAG'
                        weight='bold'
                        backgroundColor='#fef4f4'
                        padding='6px'
                        width="max-content"
                        border={!slug && '1px solid'}
                    >
                        ALL
                    </Tag>
                </CustomLink>
                {
                    tags.map((tag, index) => {
                        return (
                            <CustomLink
                                padding='0 10px'
                                margin='0 0 10px 0'
                                to={`${tagRouteBaseUrl}/${tag.node.slug}`}
                                key={index}
                                height='100%'
                            >
                                <Tag
                                    size='xs'
                                    color='TAG'
                                    weight='bold'
                                    backgroundColor='#fef4f4'
                                    padding='6px'
                                    width="max-content"
                                    border={slug == tag.node.slug ? '1px solid' : false}
                                >
                                    {tag.node.name && tag.node.name.toUpperCase()}
                                </Tag>
                            </CustomLink>
                        );
                    })
                }
            </Container>
        </>
    )
}

Filter.propTypes = {

}

export default Filter
