import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';

export const Container = styled.div`
    width: ${p => p.containerWidth ? p.containerWidth : '75%'};
    max-width: 1200px;
    margin: ${p => p.margin ? p.margin : '0 auto'};
    display: ${props => props.display};
    justify-content: ${props => props.jContent};
    height: ${props => props.height};
    flex-wrap: ${ props => props.flexWrap};
    @media ${deviceLessThan.laptopM}{
        width: 95%;
    }
    @media ${deviceLessThan.tablet} {
        margin: ${p => p.sMargin ? p.sMargin : '0 auto'};
        width: ${p => p.sWidth};
    }
`;

export const CarouselContainer = styled.div`
  margin: 53px 0 45px;
  padding: 36px 0px 29px;
  background-color: #f2f8fe;
  @media ${deviceLessThan.tablet} {
        padding: ${p => p.sPadding};
        margin: 18px 0 33px;
        text-align: ${p => p.sAlign};
        padding: 14px 20px 18px;
    }
`

export const TrendingTopicsContainer = styled.div`
    width: 75%;
    max-width: 1200px;
    background-color: ${p => p.color ? p.theme.colors[p.color] : p.theme.colors.CHQBOOK_BLUE};
    margin: 0 auto 45px;
    padding: 40px 0 20px;
    @media ${deviceLessThan.laptop}{
        width: 95%;
    }
`;

export const TrendingLinkWrapper = styled.div`
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    & > div {
            display:flex;
        }
`;

export const PostFeed = styled.section`
    display: grid;
    justify-content: space-between;
    grid-gap: 30px;
    grid-template-columns: ${p => p.gridDesktopColumns ? p.gridDesktopColumns : '1fr 1fr 1fr'};

    @media (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 680px) {
        grid-template-columns: 1fr;
    }
`;
