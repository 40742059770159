import React from 'react'
import PropTypes from 'prop-types'
import { Container, CarouselContainer } from './Components'
import Heading from '../../components/common/ui/Heading';
import { Image, ImageContainer } from '../../components/common/ui/Image';
import Text from '../../components/common/ui/Text';
import CustomCarousel from '../../components/common/CustomCarousel';
import { PostCard } from '../../components/common'

const BannerSection = ({ featuredPost }) => {
    return (
        <>
            <Container>
                <Heading
                    size='lg'
                    color='MEDIUM_BLACK'
                    weight='normal'
                    margin='60px 0 0 0'
                    sAlign='center'
                    sMargin='30px 0 0 0'
                >The Chqbook Blog</Heading>
                <ImageContainer width='256px' sMargin='0 auto'>
                    <Image src='/images/red-underline.svg' />
                </ImageContainer>
                <Text
                    opacity='0.7'
                    color='MEDIUM_BLACK'
                    size='xl'
                    margin='38px 0 0 0'
                    sAlign='center'
                    sMargin='24px 0 0 0'
                    sPadding='0 20px'
                >Read our latest articles to get financially fit</Text>
            </Container>
            <CarouselContainer>
                <Text
                    align='center'
                    color='MEDIUM_GREY'
                    margin='0 0 25px 0'
                    sMargin='0 0 18px 0'
                >Most Viewed</Text>
                <CustomCarousel
                    width='30vw'
                    padding='0 2vw 0 0'
                    mWidth='60vw'
                    sWidth='95vw'
                    sTrackWidth='max-content !important'
                    centerMode={false}
                    alignItems='flex-start'
                    jContent={featuredPost.length == 1 ? 'center' : false}
                    variableWidth={true}
                    dotColor='CHQBOOK_BLUE_DARK'
                    initialSlide={0}
                    // autoplay={true}
                    // speed= {2000}
                    // autoplaySpeed={1000}
                    // cssEase="linear"
                >
                    {
                        featuredPost.map(({ node }) => {
                            return (
                                <PostCard
                                    key={node.id}
                                    post={node}
                                    hideFooter={true}
                                />
                            );
                        })
                    }
                </CustomCarousel>
            </CarouselContainer>
        </>
    )
}

BannerSection.propTypes = {

}

export default BannerSection
